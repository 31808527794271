import FavoritesWatchLater from "./FavoritesWatchLater";

/* global Utils LoginModal */
class FavoritesWatchLaterGuest extends FavoritesWatchLater {
	loadItems() {
		const videos = Utils.getCookie("guestFavoritesArray1")
			.split(",")
			.filter(String);
		const galleries = Utils.getCookie("guestFavoritesArray2")
			.split(",")
			.filter(String);
		const arr = videos.concat(galleries);
		this.process(arr);
	}

	handleSave(el) {
		if (this.saveToCookies($(el).attr("data-list-id"))) {
			return true;
		}
		LoginModal.show($(el).attr("href"));

		return false;
	}

	saveToCookies(id) {
		let videosID = Utils.getCookie("guestFavoritesArray1")
			.split(",")
			.filter(String);
		let galleriesID = Utils.getCookie("guestFavoritesArray2")
			.split(",")
			.filter(String);
		const maxCount = 30;

		if (id.substring(0, 1) === "w") {
			// if watchlater show login
			return false;
		}

		if (id.substring(2, 3) > 2) {
			// if !video || !gallery
			return false;
		}

		if (id.substring(2, 3) === "1") {
			// video
			if ($.inArray(id, videosID) === -1) {
				if (videosID.length > maxCount) return false;
				videosID.push(id);
			} else {
				videosID = $.grep(videosID, value => value !== id);
			}
			Utils.setCookie("guestFavoritesArray1", videosID.toString(), Utils.getYears(1), Utils.getDomain(), {
				secure: true,
				sameSite: "Strict"
			});
		}

		if (id.substring(2, 3) === "2") {
			// gallery
			if ($.inArray(id, galleriesID) === -1) {
				if (galleriesID.length > maxCount) return false;
				galleriesID.push(id);
			} else {
				galleriesID = $.grep(galleriesID, value => value !== id);
			}
			Utils.setCookie("guestFavoritesArray2", galleriesID.toString(), Utils.getYears(1), Utils.getDomain(), {
				secure: true,
				sameSite: "Strict"
			});
		}

		return true;
	}
}

window.FavoritesHandler = new FavoritesWatchLaterGuest().init();
