import "./Components/simple-ajax-functions";

// es6+ scripts for logged / guest users
import Clipboard from "clipboard";

const clipboard = new Clipboard(".js-copy-clipboard");
clipboard.on("success", (e) => {
	$(e.trigger).val("Copied!");
});

/// VIDEO PLAYER DETECTION FUNCTION - #2833

window.VideoPlayerPlay = () => {
	if (document.getElementById("krpanoSWFObject")) {
		var test = setInterval(function () {
			try {
				var video = document.getElementById("krpanoSWFObject").get("plugin[video]").videoDOM;
				video.play();
				/*console.log('run ...');*/
				clearInterval(test);
			} catch (e) {
				/*console.log('fail ...');*/
			}
		}, 500);
	} else if (document.getElementById("movie")) {
		var video = document.getElementById("movie");
		video.play();
	} else if (false) {
		// TODO DELIGHT VR
	} else if (typeof VideoPlayer === "function") {
		player.play();
	} else {
		console.log("Error: no player was detected.");
	}
};

/// VIDEO PLAYER DETECTION FUNCTION END
