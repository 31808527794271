import "./scripts";
import "./Components/FavoritesWatchLater/Guest";
import "./Playlists/guest";
import "./PlayerFunction/saveSettingsGuest";

import swal from "sweetalert2";

$(document).ready(function () {
	swal.mixin({
		buttonsStyling: false,
		showCloseButton: true,
		customClass: {
			confirmButton: "swal2-confirm swal2-btn swal2-styled",
			cancelButton: "swal2-cancel swal2-btn swal2-styled",
		},
	});
	window.swal = swal;
});
