export default class FavoritesWatchLater {
	wrapper = "body";

	loadItems(data) {}
	handleClick(element) {}

	init() {
		setTimeout(() => {
			// fix for ajax loaded content
			this.handleDynamicContent("body");
		}, 700);
		this.watcher();
	}

	handleDynamicContent(target) {
		this.wrapper = target;
		this.loadItems(this.getAllIds());
	}

	getAllIds() {
		return {
			videos: this.getIds("video"),
			galleries: this.getIds("gallery"),
			images: this.getIds("image"),
			stories: this.getIds("story"),
			models: this.getIds("model"),
			playlists: this.getIds("playlist")
		};
	}

	getIds(type) {
		const arr = [];
		$(this.wrapper)
			.find(`[data-${type}-id]`)
			.each((i, item) => {
				arr.push($(item).attr(`data-${type}-id`));
			});
		return arr;
	}

	process = data => {
		$.each(data, (key, val) => {
			this.markToggleAll(val);
		});
	};

	watcher() {
		$(document).on("click", "[data-check-favorites]", e => {
			setTimeout(() => {
				// fix for ajax loaded content
				this.handleDynamicContent($(e.target).attr("data-check-favorites"));
			}, 700);
		});
		$(document).on("click", ".add-to-list", e => {
			e.preventDefault();
			if ($(this).hasClass("js-show-playlists-modal")) return false;
			if (this.handleSave($(e.currentTarget))) {
				this.markToggleAll($(e.currentTarget).attr("data-list-id"));
			}
			return false;
		});
	}

	markToggleAll(val) {
		$(`[data-list-id=${val}]`).each(this.markToggle);
	}

	markToggle() {
		if ($(".favorites-link").attr("data-js") !== "loginpopup") {
			const actionAdded = !$(this)
				.children()
				.hasClass("active");
			$(this)
				.children()
				.toggleClass("active");
			// buttons links
			if ($(this).hasClass("watchLater-link") || $(this).hasClass("favorites-link")) {
				let text = $(this).attr("data-removeText");
				if (actionAdded) {
					text = $(this).attr("data-addText");
				}
				$(this)
					.find(".text")
					.text(text);
			}

			// thumbs link
			if ($(this).hasClass("favorite") || $(this).hasClass("watchLater")) {
				$(this).toggleClass("active");
			}

			$(this).attr("title", $(this).attr(actionAdded ? "data-remove" : "data-add"));
		}
	}
}
