let simpleModal = null;
$(document).on("click", ".js-ajax-modal", function(e) {
	e.preventDefault();
	if ($(this).hasClass("download-item")) {
		console.log($(this));
		simpleModal = new Modal(undefined, "donations-padding");
	} else {
		simpleModal = new Modal("simple-ajax-modal");
	}
	simpleModal.setHeader($(this).attr("data-modal-title") || "Info");
	simpleModal.setContentAjax($(this).attr("href"), () => simpleModal.show());
	simpleModal.modal.jsclass = simpleModal;
});

$(document).on("submit", ".js-ajax-modal-form", function(e) {
	if (simpleModal) {
		e.preventDefault();
		$.ajax({
			url: $(this).attr("action"),
			type: $(this).attr("method"),
			data: $(this).serialize(),
			success: response => {
				if (typeof response === "object") {
					handleModalResponseWithSwal(response);
				} else {
					simpleModal.setContent(response);
				}
			},
			error: error => {
				simpleModal.setContent(error);
			}
		});
	}
});

$(document).on("click", ".js-ajax-modal-close", function(e) {
	if (simpleModal) {
		e.preventDefault();
		simpleModal.close();
	}
});

$(document).on("click", ".js-ajax-action-link", function(e) {
	e.preventDefault();
	$.ajax({
		url: $(this).attr("href"),
		success: response => {
			if (typeof response === "object") {
				handleModalResponseWithSwal(response);
			} else {
				simpleModal.setContent(response);
			}
		},
		error: error => showSwalError(error)
	});
});

function handleModalResponseWithSwal(response) {
	if (response.status) {
		swal.fire({
			icon: "success",
			title: response.data.text
		});
		if (simpleModal) {
			simpleModal.close();
		}
	} else {
		swal.fire({
			icon: "error",
			title: response.data.error
		});
	}
}

function showSwalError(error) {
	swal.fire({
		icon: "error",
		title: typeof error === "string" ? error : "Error!"
	});
}
