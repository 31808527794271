async function getNextVideo() {
	return $.get(`${window.location.href}&do=nextVideo`);
}

async function getPrevVideo() {
	return $.get(`${window.location.href}&do=prevVideo`);
}

$(document).on("click", ".js-next-video", e => {
	e.preventDefault();
	getNextVideo().then(response => {
		window.location.href = response.data.url;
	});
});

$(document).on("click", ".js-prev-video", e => {
	e.preventDefault();
	getPrevVideo().then(response => {
		window.location.href = response.data.url;
	});
});

$(document).on("click", ".js-playlist-shuffle", e => {
	e.preventDefault();
	if ($(e.currentTarget).hasClass("active")) {
		Utils.setCookie("shuffle", "", -1, Utils.getDomain(), {
			secure: true,
			sameSite: "Strict"
		});
	} else {
		Utils.setCookie("shuffle", 1, Utils.getYears(1), Utils.getDomain(), {
			secure: true,
			sameSite: "Strict"
		});
	}
	$(e.currentTarget).toggleClass("active");
});

/* global Modal LangJs */
$(document).on("click", ".js-playlist-share", e => {
	e.preventDefault();
	const shareModal = new Modal();
	shareModal.setHeader($(e.currentTarget).attr("title"));
	shareModal.setContent(
		`<div class="share-modal">
			<div class="text-center"><div class="addthis_inline_share_toolbox"></div></div>
			<input
				type="text"
				value="${$(e.currentTarget).attr("data-url")}"
				class="form-control"
				onclick="this.select();"
			/>
			<br><br>
			<div class="save-button-wrapper text-center">
				<input
					type="submit"
					class="btn btn-submit js-copy-clipboard"
					value="Copy to clipboard"
					data-clipboard-text="${$(e.currentTarget).attr("data-url")}"
				>
			</div>
		</div>`
	);
	shareModal.show();
});

/// Mechanika pro změnu vlastností playlistu pro issue #2701
/// zajišťuje správnou šířku wrapperu playlistových objektů, aby se neřadili pod sebe, ale vedle sebe.
$(document).ready(() => {
	if ($("#maincolumn2").hasClass("has-playlist") && $("#site-wrapper").hasClass("widescreen-enabled")) {
		//console.log('Found you a playlist!');

		// Zjisti počet itemů v playlistu
		const $playlist_length = $(".plist-thb").length;
		//console.log('playlist have: ' + $playlist_length + ' videos');

		// zjisti šířku itemu playlistu
		const $playlist_item_width = $(".plist-thb").outerWidth();
		//console.log('Plist item width:' + $playlist_item_width);

		/// zjisti šířku wrapperu itemů playlistu
		const $playlist_item_wrapper_width = $(".plist-thb-center-wrapper").outerWidth();
		//console.log('playlist wrapper width:' + $playlist_item_wrapper_width);

		// pokud je wrapper kratší, než kompletní šířka itemů vedle sebe
		if ($playlist_item_wrapper_width < $playlist_item_width * $playlist_length) {
			// nastav šířku playlistu na kompletní šířku itemů vedle sebe
			const $playlist_wrapper_set_w = $playlist_item_width * $playlist_length;

			$(".plist-thb-center-wrapper").css({
				width: $playlist_wrapper_set_w + "px"
			});
			//console.log('Wrapper width set to ' + $playlist_wrapper_set_w + 'px');
		} else {
			//console.log('Wrapper is long enought to contain playlist items.');
		}
	} else {
		//console.log('no playlist found or no widescreen is set');
		//console.log('Playlist up:' + $('#maincolumn2').hasClass('has-playlist'));
		//console.log('Widescreen up:' + $('#site-wrapper').hasClass('widescreen-enabled'));
	}
});

document.addEventListener("DOMContentLoaded", () => {
	if ($(".playlist-thumbs-wrapper").is("*")) {
		setTimeout(() => {
			const videoId = $(".block[data-video-id]").attr("data-video-id");

			$(".playlist-thumbs-wrapper")
				.scrollLeft(0)
				.scrollLeft(
					$(`[data-playlist-video-id="${videoId}"]`)
						.addClass("active")
						.position().left - 5
				);
		}, 600);
	}
});

// const playlistScroll = { before: true, after: true };
//
// function handleScrollPlaylist(addToStart) {
// 	const item_limit = 10;
// 	const videoId = $(".block[data-video-id]").attr("data-video-id");
// 	const playlistId = $(".playlist-videos-wrapper").attr("data-playlist-id");
// 	const item_width = $(".plist-thb").outerWidth();
// 	if (addToStart) {
// 		if (playlistScroll.before) {
// 			playlistScroll.before = false;
// 			const firstVideoId = $(".plist-thb")
// 				.first()
// 				.attr("data-playlist-video-id");
// 			$(".plist-thb-center-wrapper").prepend(
// 				'<div class="loading-screen"><i class="fa fa-refresh fa-spin" /></div>'
// 			);
// 			$.get(
// 				`/ajax-video-playlist-box/${videoId}/load-prev/?pl=${playlistId}&startVideoId=${firstVideoId}&limit=${item_limit}`,
// 				response => {
// 					$(".plist-thb-center-wrapper .loading-screen").remove();
// 					if (response.data.numberOfThumbs) {
// 						if (response.data.numberOfThumbs >= item_limit) {
// 							playlistScroll.before = true;
// 						}
// 						let plist_width = item_width * (response.data.numberOfThumbs + $(".plist-thb").length);
// 						$(".plist-thb-center-wrapper").css("width", plist_width + "px");
// 						$(".plist-thb-center-wrapper").prepend(response.data.html);
// 						$(".playlist-thumbs-wrapper")
// 							.delay(500)
// 							.scrollLeft($(`[data-playlist-video-id=${firstVideoId}]`).position().left - item_width);
// 					}
// 				}
// 			);
// 		}
// 	} else {
// 		if (playlistScroll.after) {
// 			playlistScroll.after = false;
// 			const lastVideoId = $(".plist-thb")
// 				.last()
// 				.attr("data-playlist-video-id");
// 			$(".plist-thb-center-wrapper").append(
// 				'<div class="loading-screen"><i class="fa fa-refresh fa-spin" /></div>'
// 			);
// 			$.get(
// 				`/ajax-video-playlist-box/${videoId}/load-next/?pl=${playlistId}&startVideoId=${lastVideoId}&limit=10`,
// 				response => {
// 					$(".plist-thb-center-wrapper .loading-screen").remove();
// 					if (response.data.numberOfThumbs >= item_limit) {
// 						playlistScroll.after = true;
// 					}
// 					$(".plist-thb-center-wrapper").append(response.data.html);
// 				}
// 			);
// 		}
// 	}
// }
//
// $(".playlist-thumbs-wrapper").on("scroll", e => {
// 	const elem = e.currentTarget;
// 	const scrollTresholdMobile = elem.scrollWidth - elem.clientWidth;
// 	if (elem.scrollLeft === 0) handleScrollPlaylist(true);
// 	if (elem.scrollLeft === scrollTresholdMobile) handleScrollPlaylist(false);
// });
